import { emitter } from '../modules/emitter'

// Classe para monitorar o progresso de recursos
class ResourceTracker {
	constructor() {
		this.total = 0
		this.remaining = 0
	}

	get any() {
		return this.remaining > 0
	}

	get percent() {
		return this.total === 0 ? 1 : 1 - this.remaining / this.total
	}

	reset() {
		this.total = 0
		this.remaining = 0
	}

	add(count = 1) {
		this.total += count
		this.remaining += count
	}

	remove(count = 1) {
		this.remaining -= count
	}
}

// Configurações e estado padrão do loader
const loader = {
	state: {
		startedAt: 0,
		progress: 0,
		img: new ResourceTracker(),
	},

	el: null,
	elProg: null,
	cssVariable: '--percentage',

	init(loaderSelector, progressSelector) {
		this.el = document.querySelector(loaderSelector)
		this.elProg = document.querySelector(progressSelector)
		this.state.startedAt = performance.now()
		this.showLoader()
	},

	showLoader() {
		this.findPreloads()
		if (this.isLoadComplete()) {
			this.completeLoader()
		} else {
			this.animate(performance.now())
		}
	},

	findPreloads() {
		const images = document.querySelectorAll('[data-preload]')
		this.state.img.total = images.length
		this.state.img.remaining = images.length

		images.forEach((img) => {
			if (!img.complete) {
				console.log(`Aguardando carregamento: ${img.src}`)
				img.addEventListener(
					'load',
					() => {
						this.state.img.remove()
						console.log(`Imagem carregada: ${img.src}`)
					},
					{ once: true }
				)

				img.addEventListener(
					'error',
					() => {
						this.state.img.remove()
						console.error(`Erro ao carregar: ${img.src}`)
					},
					{ once: true }
				)
			} else {
				console.log(`Imagem já carregada: ${img.src}`)
				this.state.img.remove()
			}
		})
	},

	isLoadComplete() {
		return !this.state.img.any
	},

	animate(timestamp) {
		const progress = this.state.progress
		if (this.isLoadComplete()) {
			this.completeLoader()
		} else {
			this.state.progress = Math.min(progress + 1, 100)
			this.updateProgress(this.state.progress)
			requestAnimationFrame(this.animate.bind(this))
		}
	},

	updateProgress(value) {
		this.el.style.setProperty(this.cssVariable, value / 100)
		if (this.elProg) {
			this.elProg.dataset.loadProgress = value
			document.querySelector('#loader [data-load-progress]').style.setProperty('--progress', value)
		}
	},

	completeLoader() {
		this.updateProgress(100)
		setTimeout(() => {
			console.log('Todas as imagens foram carregadas.')
			window.scrollTo(0, 0)
			this.el.classList.add('loaded')
			document.body.classList.add('loaded')

			setTimeout(() => {
				emitter.emit('loader:complete')
			}, 550)
		}, 500)
	},
}

// Expor o loader para o escopo global
window.loader = loader

// Inicializar o loader quando o DOM estiver pronto
document.body.classList.add('haveLoader')
document.addEventListener('DOMContentLoaded', () => {
	loader.init('#loader', '[data-load-progress]')
	window?.lenis?.stop()
})
